/*_____________________Custom Fonts_____________________*/
@font-face {
    font-family: "Centra";
    src: url("./Fonts/CentraNo2-Bold.ttf");
    font-weight: 700;
}

@font-face {
    font-family: "Centra";
    src: url("./Fonts/CentraNo2-Book.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Centra";
    src: url("./Fonts/CentraNo2-Medium.ttf");
    font-weight: 400;
}

/*_____________________Custom Fonts_____________________*/

/*_____________________Default CSS_____________________*/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
}

body {
    font-weight: 400;
    background-color: #121212 !important;
    color: #fff !important;
    font-family: "Centra", sans-serif !important;
}

body,
html {
    max-width: 100%;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    line-height: normal;
}

.App-header {
    background-color: #7b8d93;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-body {
    background-color: #6a7c83;
    min-height: 90vh;
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
}

.transparent-background {
    background-size: cover; /* Passt das Bild an den Container an */
    background-repeat: no-repeat; /* Verhindert das Wiederholen des Bildes */
    background-position: center; /* Zentriert das Bild */
    height: 90vh; /* 100% der Bildschirmhöhe */
    width: 50%; /* 60% der Bildschirmbreite */
    /* filter: grayscale(70%); Hiermit wird der Graufilter hinzugefügt */
    opacity: 0.9; /* Setzt die Transparenz auf 50% */
}

li {
    text-align: left;
}

/*_____________________Default CSS_____________________*/

/*_____________________NAVBAR_____________________*/

nav.navbar .navbar-nav .navbar-link {
    /*Navbar Button size*/
    font-size: 20px;
    /*Dicke der Buchstaben der navbar Buttons*/
    font-weight: 600;
    /*Farbe der schrift der navbar Buttons*/
    color: #fff;
    /*Ändert die durchsichtigkeit der Button SCHRIFT navbar*/
    opacity: 100%;
    /*Änder den Abstand Zwischen den navbar buttons*/
    padding: 0 35px;
}

@media (max-width: 991px) {
    nav.navbar .navbar-nav .navbar-link {
        padding: 0 25px;
        font-size: 19px;
    }
}

@media (max-width: 830px) {
    nav.navbar .navbar-nav .navbar-link {
        padding: 0 20px;
        font-size: 18px;
    }
}

nav.navbar {
    /*Änder die farbe der gesamtenNavbar*/
    background-color: #121212;
    /*Ändert die durchsichtigkeit der gesamten navbar*/
    opacity: 0.9;
    /*Erlaubt das die navbar beim scrollen an ihrer Stelle bleibt 1/2*/
    z-index: 9999;
}

/*__________NAVBAR SCROLLED___________*/

nav.navbar.scrolled {
    /*Erlaubt das die navbar beim scrollen an ihrer Stelle bleibt 2/2*/
    position: fixed;
    /*Laenge der NavBar 100%*/
    width: 100%;
    /*Wenn gescrollt wird soll die Navbar LEICHT durchsichtig sein*/
    opacity: 0.9;
    /*Die NavBar wird oben fixiert*/
    top: 0;
}

/*__________TOGGLER__________*/
/*If screen smaller then 767 toggler is activated*/
/*__________TOGGLER__________*/

@media (max-width: 767px) {
    .navBarContainer {
        font-size: 30px;
        margin: 15px 0;
        padding-left: 2%;
    }
}

.navbar-toggler-icon {
    height: 18px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 33 30'%3e%3cpath stroke='rgba(255, 255, 255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

/* Set the toggler icon to 'X' when nav is open */
.navbar-toggler-icon.toggler-open {
    height: 18px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4' d='M4 4l22 22M26 4L4 26'/%3e%3c/svg%3e");
}

/*_____________________NAVBAR_____________________*/

/*_____________________Skills Css_____________________*/
.skill {
    /*Kompletten Banner Padding auf null setzen*/
    padding: 0;
    /*Bestimmt die Section hoehe*/
    /*Verhindert dass die darunterliegende Section zu sehen ist*/
    justify-content: center;
    align-items: center;
    background: #121212;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}

.skillText {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 3%;
}

.skillBox {
    /* background: #2a2a2a ; */
    background: #121212;
    border-radius: 64px;
    text-align: center;
    /* Rosa Schatten */
    opacity: 1;
    /* Stellt sicher, dass das Bild den gesamten Container abdeckt */
    background-size: cover;
    /* Zentriert das Bild */
    background-position: center;
    /* Verhindert das Wiederholen des Bildes, falls es kleiner ist als der Container */
    background-repeat: no-repeat;
    /*Braucht man um das Bild beim scrollen zu bewegen*/
    margin-bottom: 2%;
    margin-left: 10%;
    margin-right: 10%;
}

.roundedParallax {
    border-radius: 64px;
    overflow: hidden;
    padding: 8px;
}

.skillText h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
}

/*Change Label in Progressbar*/
.progressLabel {
    font-size: 15px;
    color: white;
}

/* Change padding of progressbar */
.wrapper {
    padding-bottom: 0.5%;
}

.progressbarInRow {
    margin-left: 5%;
    margin-right: 5%;
}

@media (max-width: 767px) {
    .progressbarInRow {
        padding-left: 4%;
        padding-right: 4%;
    }
}

@media (max-width: 430px) {
    .progressbarInRow {
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media (max-width: 335px) {
    .progressbarInRow {
        padding-left: 7%;
        padding-right: 7%;
    }
}

.smallScreenBackgroundSKILLS {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 64px;
    opacity: 1;
    margin-bottom: 4%;
}

/*_____________________Skills Css_____________________*/

/*_____________________Projects_____________________*/

.project {
    padding-top: 1%;
    background-color: #121212;
    width: 100%;
    min-height: 100vh;
}

.project h2 {
    font-size: 45px;
    font-weight: 700;
    /*Titel wird im Zentrum gestellt*/
    text-align: center;
    margin-bottom: 20px;
}

.projectWrapper {
    /* Der Abstand zwischen Überschrift und Projektbox */
    padding-bottom: 10%;
    display: flex;
    flex-direction: column;
    /* Horizontale Zentrierung */
    align-items: center;
    /* Vertikale Zentrierung */
    justify-content: center;
}

.projectTitle h4 {
    /*die Höhe, die du brauchst, um zwei Zeilen unterzubringen */
    min-height: 70px; /*3 Zeilen Text ueber der Project box*/
    /* Die gleiche Höhe wie min-height */
    overflow: hidden;
    padding-left: 30px;
    padding-right: 30px;
}

.projectTitle {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

/*Hintergrund der Tabs*/
.project .nav.nav-pills {
    /*Laenge des Tabbar hintergrundes*/
    width: 90%;
    /*Stellt es in die Mitte*/
    margin: 0 auto;
    /*Ecken abgerundet*/
    border-radius: 60px;
    /*Hintergrundfarbe*/
    background-color: rgb(255 255 255 / 10%);
}

.project .nav.nav-pills .nav-item {
    /*Die Tab Buttons (Pills) sollen den ganzen Tabbar hintergrund belegen*/
    /*Wenn mehr Tabs noetig sind wieder aktivieren!!*/
    /* width: 33.33333%; */
    width: 50%;
}

.project .nav.nav-pills .nav-link {
    /*Wenn ein Tab ausgewaehlt wird bleibt der durchsichtig (Keine Blaue farbe)*/
    background-color: transparent;
    border-radius: 0;
    /*Farbe der schrift in der Tabbar*/
    color: #fff;
    /*Schrift groesse der Tabbar*/
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
}

.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}

.project .nav.nav-pills .nav-link.active {
    /*Wenn tab in tabbar angeklickt dann erscheint ein rand*/
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 50px;
}

/*BILDER DER PROJEKTE*/
.proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 10%;
    /* Aendere die groesse der Project Cards */
    width: 200px;
    height: 200px;
}

.proj-imgbx img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*Bei druebergehen erscheint farbe mit text*/
.proj-imgbx::before {
    content: "";
    background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
    opacity: 0.85;
    position: absolute;
    width: 100%;
    height: 0;
    transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
    height: 100%;
}

.proj-txtx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 100%;
}

.proj-imgbx:hover .proj-txtx {
    top: 50%;
    opacity: 1;
}

.proj-txtx h4 {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
}

.proj-txtx span {
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.8px;
}

.background-image-right {
    top: 20%;
    position: absolute;
    bottom: 0;
    width: 35%;
    right: 0;
    z-index: -4;
}

/*____________________ Projects CSS_________________*/

/*____________________Footer CSS ___________________*/
.foEl {
    padding: 0px;
}

/*____________________Footer CSS ___________________*/

/*____________________AGB - Privatepolicy - Legalnotice CSS ___________________*/
.agb,
.ppolicy,
.legaln {
    height: 100vh;
    padding: 80px 0;
    position: relative;
    background-color: #121212;
    padding: 0%;
    margin-left: 4%;
    margin-right: 4%;
}

.agb h1,
.ppolicy h1,
.legaln h1 {
    text-align: center;
    font-size: 55px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-top: 1.5%;
    margin-bottom: 20px;
}

.agb p,
.ppolicy p,
.legaln p {
    text-align: center;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 1.2;
    margin-bottom: 20px;
}

/*____________________AGB - Privatepolicy - Legalnotice CSS ___________________*/
